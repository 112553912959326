import { useEffect, useState } from "react";

// react-router components
import { Link, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import { Typography, Switch } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "components/Breadcrumbs";
import NotificationItem from "components/Items/NotificationItem";
import LogoutIcon from "@mui/icons-material/Logout";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarIconButton,
  navbarMobileMenu,
  navbarRow,
} from "components/Navbars/DashboardNavbar/styles";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useData } from "context";

// Material Dashboard 2 React context
import {
  setMiniSidenav,
  setOpenConfigurator,
  setTransparentNavbar,
  useMaterialUIController,
} from "context";
import MDBadge from "components/MDBadge";
import { getUnreadNotificationCount } from "../../../apis/apis/index";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 75,
  height: 35,
  padding: 9,
  "& .MuiSwitch-switchBase": {
    marginTop: 0,
    padding: 1,
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(35px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#000"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#302e33" : "#F5F5F5",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    width: 50,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#f0f2f5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

function DashboardNavbar(props, { absolute, light, isMini }) {
  const { toggleColorMode } = props;
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const [logoutFlag, setLogoutFlag] = useState(false);

  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const route = useLocation().pathname.split("/").slice(1);
  const navigate = useNavigate();

  const { state, dispatchNotification } = useData(); // Use the state from the context to render the notifications count
  

  const handleLogOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    sessionStorage.removeItem("token");
    localStorage.removeItem("localToken");
    localStorage.removeItem("token");
    localStorage.setItem("logoutFlag", Date.now().toString());
    navigate("/authentication/sign-in");
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "logoutFlag") {
        sessionStorage.clear();
        setLogoutFlag(true);
      }
    };

    // Listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange);

    // Clean up event listener
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (logoutFlag) {
      navigate("/authentication/sign-in");
    }
  }, [logoutFlag]);

  const initialCheckedState =
    JSON.parse(localStorage.getItem("colorMode")) ?? true;
  const [checked, setChecked] = useState(initialCheckedState);

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);

    localStorage.setItem("colorMode", JSON.stringify(isChecked));

    toggleColorMode();
  };

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /**
           The event listener that's calling the handleTransparentNavbar function when
          scrolling the window.
          */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <Link to="/notifications">
        <NotificationItem
          icon={<Icon>email</Icon>}
          title="Check new notifications"
        />
      </Link>
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { white, text } }) => ({
    color: darkMode ? white.main : text.main,
  });

  const username = sessionStorage.getItem("username");

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    getUnreadNotificationCount(token).then((res) => {
      //console.log(res);
      setUnreadNotificationCount(res.count);
      dispatchNotification({ type: "SET_DATA", payload: { data: res.count } });
    });
  }, [unreadNotificationCount]);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Link to="/">
            <IconButton size="large" disableRipple>
              <Icon sx={(theme) => iconsStyle(theme)}>home</Icon>
            </IconButton>
          </Link>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox pr={1}>
              <MaterialUISwitch
                sx={{ m: 1 }}
                checked={checked}
                onClick={(e) => handleChange(e)}
              />
            </MDBox>
            <MDBox pr={3}>
              <MDInput
                label="Search here"
                inputProps={{
                  style: {
                    WebkitTextFillColor:
                      "grey" /* TODO: Change this to the color of the text 
                                                        depending on the dark mode is enabled or not  */,
                  },
                }}
              />
            </MDBox>
            <MDBox pr={2}>
              <Typography sx={(theme) => iconsStyle(theme)}>
                {username}
              </Typography>
            </MDBox>
            <MDBox color={light ? "white" : "inherit"}>
              <Link to="/profile/">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={() => navigate("/notifications")}
              >
                {state.data ? (
                  <MDBadge badgeContent={state.data} color="error" size="small">
                    <Icon sx={iconsStyle}>notifications</Icon>
                  </MDBadge>
                ) : (
                  <Icon sx={iconsStyle}>notifications</Icon>
                )}
              </IconButton>

              <IconButton
                size="small"
                disableRipple
                sx={navbarIconButton}
                onClick={handleLogOut}
              >
                <LogoutIcon />
              </IconButton>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;