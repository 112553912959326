import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  IconButton,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { useNavigate } from "react-router-dom";
import { getEmployeeHoursInRange } from "../../../../apis/apis/index";
import dayjs from "dayjs";
import ReportDetailDialog from "../ReportDetailDialog";

export default function ReportTable({ startDate, endDate }) {
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showOnly, setShowOnly] = useState("All");
  const [errorMessage, setErrorMessage] = useState("");
  console.log("errorMessage", errorMessage);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");

  const handleOpenDialog = (employee) => {
    setSelectedEmployee(employee);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEmployee(null);
  };

  useEffect(() => {
    const fetchEmployeeHours = async () => {
      if (!startDate || !endDate) {
        return;
      }

      try {
        const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
        const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

        const data = await getEmployeeHoursInRange(
          token,
          setErrorMessage,
          handleOpenDialog,
          navigate,
          formattedStartDate,
          formattedEndDate
        );

        if (data) {
          const mappedData = data.map((row) => ({
            ...row,
            id: row.employee_id,
          }));

          setRows(mappedData);
          setFilteredRows(mappedData);
          console.log("Employee hours fetched successfully:", mappedData);
        }
      } catch (error) {
        console.error("Failed to fetch employee hours:", error);
      }
    };

    fetchEmployeeHours();
  }, [startDate, endDate, token, navigate]);

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    filterRows(value, showOnly);
  };

  const handleShowOnlyChange = (e) => {
    const value = e.target.value;
    setShowOnly(value);
    filterRows(searchText, value);
  };

  const filterRows = (searchValue, showOnlyValue) => {
    let newRows = rows.filter((row) =>
      row.employee_name.toLowerCase().includes(searchValue)
    );

    if (showOnlyValue === "Employees with missing hours") {
      newRows = newRows.filter((row) => row.working_hours < 160);
    } else if (showOnlyValue === "Employees who have met hour expectation") {
      newRows = newRows.filter((row) => row.working_hours >= 160);
    }

    setFilteredRows(newRows);
  };

  return (
    <Box
      sx={{
        borderRadius: "0.5rem",
        height: "65vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor:
          theme.palette.mode === "light" ? light.primary : dark.primary,
        padding: "1rem",
      }}
    >
      <Box display="flex" alignItems="center" marginBottom="1rem">
        <TextField
          label="Employee"
          value={searchText}
          onChange={handleSearchChange}
          sx={{
            marginRight: "1rem",
            width: "12.5rem",
            height: "2.6rem",
            "& .MuiInputBase-input": {
              color: theme.palette.mode === "light" ? light.text : dark.text2,
            },
          }}
          variant="outlined"
          size="sm"
        />

        <FormControl
          variant="outlined"
          size="sm"
          sx={{ marginRight: "1rem", width: "16rem" }}
        >
          <InputLabel>Show only</InputLabel>
          <Select
            value={showOnly}
            onChange={handleShowOnlyChange}
            label="Show only"
            sx={{
              height: "2.8rem",
              "& .MuiSelect-select": {
                color: theme.palette.mode === "light" ? light.text : dark.text2,
              },
            }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Employees with missing hours">
              Employees with missing hours
            </MenuItem>
            <MenuItem value="Employees who have met hour expectation">
              Employees who have met hour expectation
            </MenuItem>
          </Select>
        </FormControl>
      </Box>

      <DataGrid
        rows={filteredRows}
        getRowId={(row) => row.employee_id}
        columns={[
          {
            field: "employee_name",
            headerName: "Employee",
            width: 250,
            sortable: true,
            filterable: false,
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "working_hours",
            headerName: "Working Hours",
            width: 100,
            sortable: true,
            filterable: false,
            align: "center",
            headerAlign: "center",
            flex: 0.4,
            cellClassName: (params) =>
              params.value < 160 ? "lowTotalHour" : "",
          },
          {
            field: "leave_hours",
            headerName: "Leave Hours",
            width: 100,
            sortable: true,
            filterable: false,
            align: "center",
            headerAlign: "center",
            flex: 0.5,
          },
          {
            field: "actions",
            headerName: "Actions",
            width: 170,
            sortable: false,
            filterable: false,
            align: "center",
            headerAlign: "center",
            flex: 0.5,
            renderCell: (params) => (
              <Box display="flex" justifyContent="center" width="100%">
                {params.row.working_hours > 0 && (
                  <IconButton
                    sx={{ padding: "0.25rem" }}
                    onClick={() => handleOpenDialog(params.row)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                )}
              </Box>
            ),
          },
        ]}
        autoHeight={false}
        hideFooter
        disableColumnMenu={true}
        sx={{
          height: "100%",
          width: "100%",
          maxWidth: "100%",
          ".MuiDataGrid-cell": {
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          },
          ".MuiDataGrid-columnHeader": {
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          },
          ".lowTotalHour": {
            color: theme.palette.mode === "light" ? light.error : dark.error,
          },
        }}
      />

      <ReportDetailDialog
        open={openDialog}
        onClose={handleCloseDialog}
        employee={selectedEmployee}
        workingHour={selectedEmployee?.working_hours}
        leaveHour={selectedEmployee?.leave_hours}
        startDate={startDate}
        endDate={endDate}
      />
    </Box>
  );
}

