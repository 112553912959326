import React, { useState } from "react";
import EmployeeReports from "./EmployeeReports";
import ProjectReports from "./ProjectReports";
import CompanyReports from "./CompanyReports";
import { Box, Tabs, Tab } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

const Reports = () => {
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{
        marginTop: "1rem",
        height: "80vh", // Ensure the full height is used
        overflow: "hidden", // Prevent overflow
        display: "flex",
        flexDirection: "column", // Ensure the layout is columnar
      }}
    >
      <Tabs
        sx={{
          backgroundColor:
            theme.palette.mode === "light" ? light.secondary : dark.secondary,
        }}
        value={tabValue}
        onChange={handleTabChange}
      >
        <Tab label="Employee Reports" />
        <Tab label="Project Reports" />
        <Tab label="Company Reports" />
      </Tabs>

      <Box sx={{ marginTop: "1rem", flexGrow: 1, overflow: "hidden" }}>
        {tabValue === 0 && <EmployeeReports />}
        {tabValue === 1 && <ProjectReports />}
        {tabValue === 2 && <CompanyReports/>}
      </Box>
    </Box>
  );
};

export default Reports;