import React, { useState } from "react";
import { IconButton, Box, Button } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css"; 
import './dateRangePicker.css';

export default function Scheduler({ isOpen, toggleDrawer, onSearch }) {
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const now = dayjs();
  const firstDayOfMonth = now.startOf("month");

  const [dateRange, setDateRange] = useState([
    {
      startDate: firstDayOfMonth.toDate(), 
      endDate: now.toDate(),
      key: "selection",
    },
  ]);

  const handleSearchClick = () => {

    const { startDate, endDate } = dateRange[0];


    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

    onSearch(formattedStartDate, formattedEndDate); 
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "65vh", 
        borderRadius: "0.5rem",
        width: isOpen ? "87rem" : "3rem", 
        transition: "width 0.3s ease",
        backgroundColor:
          theme.palette.mode === "light" ? light.primary : dark.primary,
        overflow: "hidden",
      }}
    >

      <IconButton onClick={toggleDrawer} sx={{ height: "100%" }}>
        {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>

      {isOpen && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            padding: "1rem",
          }}
        >

          <Box
            sx={{
              flexGrow: 1,
              paddingLeft: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                marginTop: "1rem",
              }}
            >
              <DateRangePicker
                onChange={(item) => setDateRange([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
                className="custom-date-range-picker"
              />
            </Box>


            <Button
              variant="contained"
              color="primary"
              onClick={handleSearchClick}
              sx={{
                alignSelf: "center",
                marginTop: "1.5rem",
                backgroundColor:
                  theme.palette.mode === "light"
                    ? light.secondary
                    : dark.secondary,

                color: theme.palette.mode === "light" ? light.text : dark.text,

                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : "#6e6e73"
                }`,

                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? light.secondary
                      : dark.secondary,
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text,
                },
              }}
            >
              Search
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
