import { useState, useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  fetchNotifications,
  getUnreadNotificationCount,
  markAllAsReadNotifications,
} from "../../apis/apis/index";
import { useData } from "context";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";
import NotificationComponent from "./notification";

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [filter, setFilter] = useState("all"); // Filter state to control view (all/unread)
  const token = sessionStorage.getItem("token");
  const { dispatchNotification } = useData();
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  console.log("notifications", notifications);

  // Fetch notifications
  const getNotifications = () => {
    setLoading(true);
    fetchNotifications(token)
      .then((fetchedNotifications) => {
        setNotifications(fetchedNotifications);
      })
      .catch((error) => {
        setErrorMessage(
          error.message || "An error occurred while fetching notifications."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (token) {
      getNotifications();
    }
  }, [token]);

  // Mark all notifications as read
  const markAllAsRead = async () => {
    await markAllAsReadNotifications(token);
    getNotifications();
    getUnreadNotificationCount(token).then((res) => {
      dispatchNotification({ type: "SET_DATA", payload: { data: res.count } });
    });
  };

  // Filtered notifications based on filter state
  const filteredNotifications =
    filter === "unread"
      ? notifications.filter((notification) => !notification.is_read)
      : notifications;

  return (
    <Box
      sx={{
        backgroundColor:
          theme.palette.mode === "dark" ? dark.primary : light.primary,
        border: `1px solid ${
          theme.palette.mode === "light" ? light.border : "#6e6e73"
        }`,
        borderRadius: "10px",
        margin: "2rem auto",
        width: "100%",
        maxWidth: "90%",
        height: "75vh", // Use viewport height for responsiveness
        display: "flex",
        flexDirection: "column",
        justifyContent: notifications.length === 0 ? "center" : "flex-start", // Center content if no notifications
        alignItems: "center",
        padding: "1rem",
        [theme.breakpoints.up("md")]: { maxWidth: "70%", height: "85vh" }, // Adjust heights with breakpoints
        [theme.breakpoints.up("lg")]: { maxWidth: "60%", height: "80vh" },
        [theme.breakpoints.up("xl")]: { maxWidth: "50%", height: "75vh" },
      }}
    >
      {loading ? (
        <Typography
          sx={{
            color: theme.palette.mode === "dark" ? dark.text : light.text,
            textAlign: "center",
          }}
        >
          Loading notifications...
        </Typography>
      ) : errorMessage ? (
        <Typography
          sx={{
            color: theme.palette.mode === "dark" ? dark.text : light.text,
            textAlign: "center",
          }}
        >
          {errorMessage}
        </Typography>
      ) : notifications.length === 0 ? (
        <Typography
          sx={{
            color: theme.palette.mode === "dark" ? dark.text : light.text,
            textAlign: "center",
          }}
        >
          No notifications at the moment.
        </Typography>
      ) : (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%", mb: 2 }}
          >
            <Typography
              sx={{
                color: theme.palette.mode === "dark" ? dark.text : light.text,
                fontSize: "1.25rem",
              }}
              variant="h5"
            >
              Notifications
            </Typography>
            <Button
              onClick={markAllAsRead}
              sx={{
                backgroundColor:
                  theme.palette.mode === "dark" ? dark.primary : light.primary,
                color: theme.palette.mode === "dark" ? dark.text : light.text,
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : dark.border
                }`,
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? dark.secondary
                      : light.secondary,
                  color: theme.palette.mode === "dark" ? dark.text : light.text,
                },
                fontSize: "0.875rem",
                padding: "0.5rem 1rem",
              }}
            >
              Mark All As Read
            </Button>
          </Box>

          {/* Filter Buttons */}
          <Box
            display="flex"
            justifyContent="flex-start"
            sx={{ width: "100%", mb: 3 }}
          >
            <Button
              onClick={() => setFilter("all")}
              variant={filter === "all" ? "contained" : "outlined"}
              sx={{
                marginRight: "1rem",
                backgroundColor:
                  filter === "all"
                    ? theme.palette.mode === "dark"
                      ? dark.secondary
                      : light.secondary
                    : theme.palette.mode === "dark"
                    ? dark.primary
                    : light.primary,
                color: theme.palette.mode === "dark" ? dark.text : light.text,
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : dark.border
                }`,
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? dark.secondary
                      : light.secondary,
                  color: theme.palette.mode === "dark" ? dark.text : light.text,
                },
              }}
            >
              All
            </Button>
            <Button
              onClick={() => setFilter("unread")}
              variant={filter === "unread" ? "contained" : "outlined"}
              sx={{
                backgroundColor:
                  filter === "unread"
                    ? theme.palette.mode === "dark"
                      ? dark.secondary
                      : light.secondary
                    : theme.palette.mode === "dark"
                    ? dark.primary
                    : light.primary,
                color: theme.palette.mode === "dark" ? dark.text : light.text,
                border: `1px solid ${
                  theme.palette.mode === "light" ? light.border : dark.border
                }`,
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? dark.secondary
                      : light.secondary,
                  color: theme.palette.mode === "dark" ? dark.text : light.text,
                },
              }}
            >
              Unread
            </Button>
          </Box>

          {/* Notifications List */}
          <Box sx={{ width: "100%", overflowY: "auto", flexGrow: 1 }}>
            <Grid container spacing={0}>
              {filteredNotifications.map((notification, index) => (
                <Grid item xs={12} key={notification.id || index}>
                  <NotificationComponent
                    notification={notification}
                    token={token}
                    setErrorMessage={setErrorMessage}
                    getNotifications={getNotifications}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Notifications;