import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
} from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

export default function ReportDetailDialog({
  open,
  onClose,
  employee,
  workingHour,
  leaveHour,
  startDate,
  endDate,
}) {
  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;
  if (!employee) return null;

  const projectData = employee.project_work_data.reduce((acc, project) => {
    if (!acc[project.project_abbr]) {
      acc[project.project_abbr] = {};
    }
    if (!acc[project.project_abbr][project.workpackage_name]) {
      acc[project.project_abbr][project.workpackage_name] = 0;
    }
    acc[project.project_abbr][project.workpackage_name] += project.hours;
    return acc;
  }, {});

  const pieData = Object.entries(projectData).map(
    ([project_abbr, workpackages]) => ({
      id: project_abbr,
      label: project_abbr,
      value: Object.values(workpackages).reduce((a, b) => a + b, 0),
    })
  );

  const legendThreshold = 8;
  const limitedPieData =
    pieData.length > legendThreshold
      ? [
          ...pieData.slice(0, legendThreshold),
          { id: "...", label: "...", value: 0 },
        ]
      : pieData;

  const totalHours = pieData.reduce((sum, project) => sum + project.value, 0);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          margin: "auto",
          maxWidth: "30rem",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontWeight: 600,
          fontSize: "1rem",
          backgroundColor:
            theme.palette.mode === "light" ? light.primary : dark.primary,
          color: theme.palette.mode === "light" ? light.text : dark.text2,
          borderBottom: `1px solid ${
            theme.palette.mode === "light" ? light.border : "#6e6e73"
          }`,
        }}
      >
        <Typography
          component="span"
          sx={{
            display: "block",
            fontWeight: 600,
            fontSize: "1.2rem",
          }}
        >
          {`${employee.employee_name} Report`}
        </Typography>

        <Typography
          component="span"
          sx={{
            display: "block",
            fontSize: "0.875rem",
          }}
        >
          {`between ${startDate} and ${endDate}`}
        </Typography>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          backgroundColor:
            theme.palette.mode === "light" ? light.primary : dark.primary,
        }}
      >
        {/* Pie Chart */}
        <Box
          sx={{
            flex: "0 0 auto",
            display: "flex",
            justifyContent: "center",
            minHeight: "18.75rem",
            position: "relative",
          }}
        >
          <PieChart
            margin={{
              right: 150,
            }}
            sx={{
              "& .MuiLegend-root": {
                justifyContent: "flex-end",
                marginLeft: "8rem",
              },
            }}
            animation={false}
            series={[
              {
                innerRadius: 70,
                outerRadius: 100,
                paddingAngle: 0,
                cornerRadius: 0,
                startAngle: 0,
                endAngle: 460,
                data: limitedPieData,
              },
            ]}
          />

          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: `translate(${
                totalHours.toFixed(0).length === 1
                  ? "-200%"
                  : totalHours.toFixed(0).length === 2
                  ? "-195%"
                  : "-170%"
              }, -50%)`,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              pointerEvents: "none",
            }}
          >
            <Typography
              variant="body1"
              align="center"
              sx={{
                fontSize: "1.2rem",
                color: theme.palette.mode === "light" ? light.text : dark.text2,
              }}
            >
              total
            </Typography>
            <Typography
              variant="h2"
              align="center"
              sx={{
                fontWeight: 800,
                color: theme.palette.mode === "light" ? light.text : dark.text2,
              }}
            >
              {totalHours.toFixed(0)}
            </Typography>
            <Typography
              variant="body1"
              align="center"
              sx={{
                fontSize: "1.2rem",
                color: theme.palette.mode === "light" ? light.text : dark.text2,
              }}
            >
              hours
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: "0.5rem",
          }}
        >
          <Typography
            variant="body1"
            align="center"
            sx={{
              fontSize: "1rem",
              color: theme.palette.mode === "light" ? light.text : dark.text2,
            }}
          >
            <strong>Working Hours:</strong> {workingHour}
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{
              fontSize: "1rem",
              color: theme.palette.mode === "light" ? light.text : dark.text2,
            }}
          >
            <strong>Leave Hours:</strong> {leaveHour}
          </Typography>
        </Box>

        <Box
          sx={{
            flex: "1 1 auto",
            overflowY: "auto",
            padding: "0.5rem",
            border: `1px solid ${
              theme.palette.mode === "light" ? light.border : "#6e6e73"
            }`,
          }}
        >
          {Object.entries(projectData).map(([project_abbr, workpackages]) => (
            <Box key={project_abbr} mb={2}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  color:
                    theme.palette.mode === "light" ? light.text : dark.text,
                }}
              >
                {project_abbr}
              </Typography>
              <ul>
                {Object.entries(workpackages).map(
                  ([workpackage_name, hours]) => (
                    <li key={workpackage_name}>
                      <Typography
                        variant="body2"
                        sx={{
                          color:
                            theme.palette.mode === "light"
                              ? light.text
                              : dark.text2,
                        }}
                      >
                        {workpackage_name} - {hours.toFixed(1)} hours
                      </Typography>
                    </li>
                  )
                )}
              </ul>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}