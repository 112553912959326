import axios from "../../axios";
import { handleTokenExpiration } from "../../axios/tokenUtils";

const getAllEmployeesForProject = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/users/get-all-employees-for-project", {
      headers: {
        Authorization: token,
      },
      params: {},
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchNotes = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  date
) => {
  try {
    const res = await axios.get("api/users/fetch-user-notes/", {
      params: { date: date },
      headers: {
        Authorization: token,
      },
    });
    //console.log("res: ", res.data);
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchNotesByDate = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  date
) => {
  try {
    const res = await axios.get("api/users/fetch-user-notes/", {
      params: { date: date },
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const addUserNote = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  note,
  date
) => {
  try {
    const res = await axios.post(
      "api/users/add-user-note/",
      {
        title: note,
        execution_date: date,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const updateUserNote = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  noteId,
  done
) => {
  try {
    const res = await axios.patch(
      `api/users/update-user-note/${noteId}`,
      { completion: done },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
  //console.log("done: ", done);
};

const deleteUserNote = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  noteId
) => {
  try {
    const res = await axios.delete(`api/users/delete-user-note/${noteId}`, {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const getTasks = async (
  id,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get(`/api/project/${id}/tasks/`, {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const getTasksPieChart = async (
  id,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get(`/api/project/${id}/tasks-pie-chart/`, {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const getChangelog = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/auth/get-changelogs/", {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const getChangelogTypes = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/auth/get-changelog-types/", {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const submitChangelog = async (
  token,
  changelogData,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.post("api/auth/create-changelog/", changelogData, {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const updateChangelog = async (
  token,
  changelogData,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.patch(
      `api/auth/update-changelog/${changelogData.id}/`,
      changelogData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const deleteChangelog = async (
  changelogId,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.delete(
      `api/auth/delete-changelog/${changelogId}/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const getFeedbackTypes = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/auth/get-feedback-types/", {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const submitFeedback = async (
  token,
  feedbackData,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.post("api/auth/submit-feedback/", feedbackData, {
      headers: {
        Authorization: token,
      },
    });

    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    console.error("Error in submitting feedback:", error);
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const getFeedbacks = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/auth/get-feedbacks/", {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const markFeedbackAsNoted = async (
  feedbackId,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.patch(
      `api/auth/mark-feedback-as-noted/${feedbackId}/`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const deleteFeedback = async (
  feedbackId,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.delete(`api/auth/delete-feedback/${feedbackId}/`, {
      headers: {
        Authorization: token,
      },
    });

    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const getDayOffForms = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  page,
  employeeFilter,
  dayOffStatusFilter,
  dayOffExcuseTypeFilter,
  sortOptionFilter
) => {
  try {
    const res = await axios.get(`api/users/get-leave-forms?/`, {
      params: {
        page: page,
        employee_filter: employeeFilter,
        status_filter: dayOffStatusFilter,
        excuse_type_filter: dayOffExcuseTypeFilter,
        sort_option_filter: sortOptionFilter,
      },
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const updateDayOffForms = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  data,
  dayOffId
) => {
  try {
    const res = await axios.patch(
      `api/users/update-leave-form/${dayOffId}`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const getHierarchyStructure = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/users/get-hierarchy-structure", {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchNotifications = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/auth/fetch-notifications/", {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const updateNotificationReadStatus = async (
  isRead,
  notificationId,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.patch(
      `api/auth/update-read-status/${notificationId}/`,
      { isRead: isRead },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (res && res.status === 200) {
      return res.data; // Returns the updated notification data
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const markAllAsReadNotifications = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.patch(
      "api/auth/mark-all-as-read-notifications/",
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (res && res.status === 200) {
      return res.data; // Returns the updated notification data
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const getUnreadNotificationCount = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/auth/get-unread-notification-count/", {
      headers: {
        Authorization: token,
      },
    });

    if (res && res.status === 200) {
      return res.data; // Returns the updated notification data
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const updateProject = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  values
) => {
  try {
    const res = await axios.post("api/project/update-project", values, {
      headers: {
        Authorization: token,
      },
    });

    if (res && res.status === 201) {
      return res.data; // Returns the updated notification data
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const toggleFavoriteWorkSpace = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  workspaceId
) => {
  try {
    const res = await axios.post(
      `api/project/toggle-favorite-work-space/${workspaceId}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (res && res.status === 200) {
      return res.data; // Returns the updated notification data
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const getFavoriteWorkSpaces = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/project/get-user-favorite-workspace-ids", {
      headers: {
        Authorization: token,
      },
    });

    if (res && res.status === 200) {
      return res.data; // Returns the updated notification data
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const createProject = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  values
) => {
  try {
    const res = await axios.post("api/project/create-project", values, {
      headers: {
        Authorization: token,
      },
    });

    if (res && res.status === 201) {
      return res.data; // Returns the updated notification data
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const getClients = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/project/clients/", {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};
const updateClient = async (
  client_id,
  clientData,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.patch(
      `api/project/clients/update/${client_id}/`,
      clientData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};
const createClient = async (
  values,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.post("api/project/clients/create/", values, {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};
const getProjects = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/project/all_projects/", {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const deleteClient = async (
  client_id,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.delete(`api/project/clients/${client_id}/delete/`, {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchActivities = async (
  projectId,
  workPackageId,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get(
      `/api/project/${projectId}/workpackage/${workPackageId}/activities/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const updateActivityStatus = async (
  projectId,
  workPackageId,
  activityId,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  status
) => {
  try {
    const res = await axios.patch(
      `/api/project/${projectId}/workpackage/${workPackageId}/activity/${activityId}/`,
      { status: status },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const updateActivity = async (
  projectId,
  workPackageId,
  activityId,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  activity
) => {
  try {
    const res = await axios.patch(
      `/api/project/${projectId}/workpackage/${workPackageId}/activity/${activityId}/`,
      activity,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      setErrorMessage("Internal server error. Please check the date format.");
    }
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const getDateOfWorkPackages = async (
  projectId,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get(
      `/api/project/${projectId}/work-package-dates/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const deleteWorkPackage = async (
  projectId,
  workPackageId,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.delete(
      `/api/project/${projectId}/workpackage/${workPackageId}/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const createActivity = async (
  projectId,
  workPackageId,
  activity,
  token,
  setErrorMessage,
  navigate
) => {
  try {
    const res = await axios.post(
      `/api/project/${projectId}/workpackage/${workPackageId}/activities/`,
      activity,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      setErrorMessage(
        "An internal server error occurred. Please check your input and try again."
      );
    }
    handleTokenExpiration(error, setErrorMessage, navigate);
  }
};

const deleteActivity = async (
  projectId,
  workPackageId,
  activityId,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.delete(
      `/api/project/${projectId}/workpackage/${workPackageId}/activities/${activityId}/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchMilestones = async (
  activityId,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get(`/api/project/${activityId}/milestones/`, {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const createMilestone = async (
  activityId,
  milestone,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.post(
      `/api/project/${activityId}/milestones/create/`,
      milestone,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const deleteMilestone = async (
  activityId,
  milestoneId,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.delete(
      `/api/project/${activityId}/milestones/${milestoneId}/delete/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const updateMilestone = async (
  activityId,
  milestoneId,
  milestone,
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.patch(
      `/api/project/${activityId}/milestones/${milestoneId}/`,
      milestone,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchEmployees = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/users/get-employees/", {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchSurveys = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/users/fetch-surveys/", {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
    throw error;
  }
};

const createSurvey = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  values
) => {
  try {
    const res = await axios.post("api/users/create-survey/", values, {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    console.error("Backend Error: ", error.response?.data || error.message);
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
    throw error;
  }
};

const createSurveyQuestions = async (token, surveyId, questionsData) => {
  try {
    const res = await axios.post(
      `api/users/surveys/${surveyId}/questions/`,
      questionsData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    console.error("Backend Error: ", error.response?.data || error.message);
    throw error;
  }
};

const fetchSurveyById = async (token, surveyId, setErrorMessage) => {
  try {
    const res = await axios.get(`api/users/fetch-survey/${surveyId}/`, {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    setErrorMessage(error.message);
    throw error;
  }
};

const editSurvey = async (token, surveyId, surveyData) => {
  try {
    const res = await axios.put(
      `api/users/edit-survey/${surveyId}/`,
      surveyData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};

const deleteSurvey = async (token, surveyId) => {
  try {
    const res = await axios.delete(`api/users/delete-survey/${surveyId}/`, {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};

const publishSurvey = async (token, surveyId) => {
  try {
    const res = await axios.patch(
      `api/users/publish-survey/${surveyId}/`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};

const validatePublisherGroup = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("/api/users/validate-publisher-group/", {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      setErrorMessage(error.response.data.detail);
    } else if (error.response && error.response.status === 401) {
      setErrorMessage("Unauthorized: Please log in again.");
    } else {
      console.error("Error validating publisher group:", error);
      setErrorMessage("An unexpected error occurred.");
    }
  }
};

const submitSurveyAnswers = async (token, surveyId, answers) => {
  try {
    const response = await axios.post(
      `/api/users/submit-survey-answers/${surveyId}/`,
      {
        answers: answers.answers,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (typeof setErrorMessage === "function") {
      console.error(error.response.data.detail);
    } else {
      console.error("Error submitting survey answers:", error);
    }
    throw error;
  }
};

const submitSurveyAnswersAnonymously = async (
  token,
  surveyId,
  answers,
  anonymousIdentifier
) => {
  try {
    const response = await axios.post(
      `/api/users/submit-survey-answers/${surveyId}/`,
      {
        is_anonymous: true,
        anonymous_identifier: anonymousIdentifier,
        answers: answers,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting survey answers anonymously:", error);
    throw error;
  }
};

const fetchSurveyAnswers = async (
  token,
  surveyId,
  employeeId,
  setErrorMessage,
  handleOpenDialog
) => {
  try {
    const res = await axios.get(
      `/api/users/fetch-survey-answers/${surveyId}/${employeeId}/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (res && res.data) {
      console.log("Survey answers:", res.data);
      return res.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      setErrorMessage(error.response.data.detail);
      handleOpenDialog();
    } else {
      console.error("Error fetching survey answers:", error);
    }
  }
};

const fetchAnonymousSurveyAnswers = async (
  token,
  surveyId,
  setErrorMessage,
  handleOpenDialog
) => {
  try {
    const response = await axios.get(
      `/api/users/fetch-anonymous-survey-answers/${surveyId}/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (typeof setErrorMessage === "function") {
      console.error(error.response.data.detail);
    } else {
      console.error("Error fetching anonymous survey answers:", error);
    }
    throw error;
  }
};

const fetchEmployeesWhoSubmitted = async (
  token,
  surveyId,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get(`api/users/submitted-employees/${surveyId}/`, {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      console.log("Submitted employees:", res.data);
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchAllSurveyAnswers = async (token, surveyId, setErrorMessage) => {
  try {
    const res = await axios.get(
      `/api/users/fetch-all-survey-answers/${surveyId}/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      console.log("Fetched survey answers:", res.data);
      return res.data;
    }
  } catch (error) {
    console.error("Error fetching survey answers:", error);
    setErrorMessage(error.message);
    throw error;
  }
};

const endSurvey = async (token, surveyId) => {
  try {
    const res = await axios.patch(
      `api/users/end-survey/${surveyId}/`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};

const fetchEmployeeProjectWorkPackages = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  employeeId
) => {
  try {
    const response = await axios.get(
      "api/users/get-employee-project-work-package",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchTopWorkSpaces = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/project/fetch-user-top-work-spaces-2", {
      headers: {
        Authorization: token,
      },
    });
    if (res.status === 204) {
      throw new Error("No favorited work spaces found");
    }
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchWorkSpaces = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get(
      "api/project/fetch-user-top-work-spaces-by-project",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchUserAssignedTasks = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/project/fetch-user-assigned-tasks", {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchTasksAssignedByUser = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/project/fetch-assigned-tasks-by-user", {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchProjectsWorkPackagesWorkSpaces = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/project/fetch-projects-work-packages/", {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchProjectTasks = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/project/projects-tasks/", {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchWorkSpaceInformation = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  workspaceId
) => {
  try {
    const res = await axios.get(
      `api/project/fetch-work-space-info/${workspaceId}/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchAllProjects = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/project/fetch_all_projects/", {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchCustomProjectLabels = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  projectId
) => {
  try {
    const res = await axios.get(
      `api/project/fetch-project-labels/${projectId}/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchDefaultProjectLabels = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/project/fetch-default-project-labels/", {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const createProjectLabel = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  projectId,
  label
) => {
  try {
    const res = await axios.post(
      `api/project/create-project-label/${projectId}/`,
      label,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const deleteProjectLabel = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  projectId,
  labelId
) => {
  try {
    const res = await axios.delete(
      `api/project/delete-project-label/${projectId}/${labelId}/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const editProjectLabel = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  projectId,
  labelId,
  label
) => {
  try {
    const res = await axios.patch(
      `api/project/edit-project-label/${projectId}/${labelId}/`,
      label,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchTaskLabels = async (
  token,
  taskId,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get(`api/project/fetch-labels-of-task/${taskId}/`, {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const updateTaskLabels = async (
  token,
  taskId,
  labelIds,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    console.log(`Updating task ${taskId} with labels:`, {
      label_ids: labelIds,
    });
    const res = await axios.post(
      `/api/project/update-task-labels/${taskId}/`,
      { labels: labelIds },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const changePassword = async (
  token,
  oldPassword,
  newPassword,
  confirmPassword
) => {
  try {
    const res = await axios.patch(
      `api/users/change-password/`,
      {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};

const resetPassword = async (email) => {
  try {
    const res = await axios.post(`api/users/reset-password/`, {
      email: email,
    });

    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};

const fetchProductFilterOptions = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get(
      `api/inventory/get-product-filtering-options/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchLabs = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get(`api/inventory/get-labs`, {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const createLab = async (token, setErrorMessage, handleOpenDialog, labData) => {
  try {
    const res = await axios.post("api/inventory/create-lab/", labData, {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog);
  }
};

const deleteLab = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  labId,
  showSnackbar
) => {
  try {
    const res = await axios.delete(`api/inventory/delete-lab/${labId}/`, {
      headers: {
        Authorization: token,
      },
    });
    showSnackbar("Lab deleted successfully!", "success");
    return res.data;
  } catch (error) {
    showSnackbar("Failed to delete lab.", "error");
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog);
  }
};

const fetchCategorySets = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get(`api/inventory/get-categorysets`, {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const createCategorySet = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  categorySetData
) => {
  try {
    const res = await axios.post(
      "api/inventory/create-categoryset/",
      categorySetData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog);
  }
};

const deleteCategorySet = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  categorySetId
) => {
  try {
    const res = await axios.delete(
      `api/inventory/delete-categoryset/${categorySetId}/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog);
  }
};

const fetchCategories = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get(`api/inventory/get-categories`, {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const createCategory = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  categoryData
) => {
  try {
    const res = await axios.post(
      "api/inventory/create-category/",
      categoryData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog);
  }
};

const deleteCategory = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  categoryId
) => {
  try {
    const res = await axios.delete(
      `api/inventory/delete-category/${categoryId}/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog);
  }
};

const fetchProducts = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get(`api/inventory/get-products/`, {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const createProduct = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  formData,
  showSnackbar
) => {
  try {
    const res = await axios.post("api/inventory/create-product/", formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    });
    showSnackbar("Product created successfully!", "success");
    return res.data;
  } catch (error) {
    showSnackbar("Failed to create product.", "error");
    if (error.response && error.response.data) {
      setErrorMessage(error.response.data);
    }
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog);
  }
};

const updateProduct = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  productId,
  values,
  showSnackbar
) => {
  try {
    const res = await axios.patch(
      `api/inventory/update-product/${productId}/`,
      values,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    showSnackbar("Product updated successfully!", "success");
    return res.data;
  } catch (error) {
    showSnackbar("Failed to update product.", "error");
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog);
  }
};

const deleteProduct = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  productId,
  showSnackbar
) => {
  try {
    const res = await axios.delete(
      `api/inventory/delete-product/${productId}/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    showSnackbar("Product deleted successfully!", "success");
    return res.data;
  } catch (error) {
    showSnackbar("Failed to delete product.", "error");
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog);
  }
};

const getEmployeesFullName = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get(`api/users/get-employees-id-full-name/`, {
      headers: {
        Authorization: token,
      },
    });
    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const getEmployeeHoursInRange = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  startDate,
  endDate
) => {
  try {
    const res = await axios.get(
      `api/project/get-employee-hours-in-range/${startDate}/${endDate}/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const fetchProjectsForReport = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate
) => {
  try {
    const res = await axios.get("api/project/get-projects-for-report", {
      headers: {
        Authorization: token,
      },
    });
    return res.data;
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

const getProjectEmployeeHoursInRange = async (
  token,
  setErrorMessage,
  handleOpenDialog,
  navigate,
  projectId,
  startDate,
  endDate
) => {
  try {
    const res = await axios.get(
      `api/project/get-project-employee-hours-in-range/${projectId}/${startDate}/${endDate}/`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (res && res.data) {
      return res.data;
    }
  } catch (error) {
    handleTokenExpiration(error, setErrorMessage, handleOpenDialog, navigate);
  }
};

export {
  deleteClient,
  updateClient,
  createClient,
  getProjects,
  getClients,
  createProject,
  updateProject,
  getAllEmployeesForProject,
  getChangelog,
  getChangelogTypes,
  submitChangelog,
  updateChangelog,
  deleteChangelog,
  getFeedbackTypes,
  submitFeedback,
  getFeedbacks,
  markFeedbackAsNoted,
  deleteFeedback,
  getDayOffForms,
  updateDayOffForms,
  getHierarchyStructure,
  fetchNotifications,
  updateNotificationReadStatus,
  markAllAsReadNotifications,
  getUnreadNotificationCount,
  fetchNotes,
  fetchNotesByDate,
  addUserNote,
  updateUserNote,
  deleteUserNote,
  getTasks,
  getTasksPieChart,
  getFavoriteWorkSpaces,
  toggleFavoriteWorkSpace,
  fetchActivities,
  updateActivityStatus,
  updateActivity,
  getDateOfWorkPackages,
  deleteWorkPackage,
  fetchEmployees,
  createActivity,
  deleteActivity,
  fetchMilestones,
  createMilestone,
  deleteMilestone,
  updateMilestone,
  fetchSurveys,
  createSurvey,
  createSurveyQuestions,
  fetchSurveyById,
  deleteSurvey,
  publishSurvey,
  validatePublisherGroup,
  submitSurveyAnswers,
  submitSurveyAnswersAnonymously,
  editSurvey,
  fetchSurveyAnswers,
  fetchAnonymousSurveyAnswers,
  fetchEmployeesWhoSubmitted,
  fetchAllSurveyAnswers,
  endSurvey,
  fetchEmployeeProjectWorkPackages,
  fetchTopWorkSpaces,
  fetchUserAssignedTasks,
  fetchTasksAssignedByUser,
  fetchWorkSpaces,
  fetchProjectsWorkPackagesWorkSpaces,
  fetchProjectTasks,
  fetchWorkSpaceInformation,
  fetchAllProjects,
  fetchCustomProjectLabels,
  createProjectLabel,
  deleteProjectLabel,
  editProjectLabel,
  fetchDefaultProjectLabels,
  fetchTaskLabels,
  updateTaskLabels,
  changePassword,
  resetPassword,
  fetchProducts,
  fetchProductFilterOptions,
  fetchLabs,
  fetchCategories,
  fetchCategorySets,
  createProduct,
  updateProduct,
  deleteProduct,
  createLab,
  deleteLab,
  createCategorySet,
  deleteCategorySet,
  createCategory,
  deleteCategory,
  getEmployeesFullName,
  getEmployeeHoursInRange,
  fetchProjectsForReport,
  getProjectEmployeeHoursInRange,
};
