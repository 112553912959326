/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import UpdateEmployee from "layouts/tables/employee/UpdateEmployee";
import Dashboard from "./layouts/dashboard";
import Tables from "./layouts/tables";
//import Billing from "./layouts/billing";
//import Notifications from "./layouts/notifications";
import Projects from "./layouts/Projects";
import Profile from "./layouts/profile";
import Scheduler from "./layouts/scheduler";
//import CreateProject from "./layouts/admin/CreateProject";
import ProjectUpdate from "layouts/Projects/update/ProjectUpdate";
import ProjectCreate from "layouts/Projects/create/ProjectCreate";
import ClientInfo from "layouts/Clients/ClientInfo";
import ViewProject from "./layouts/Projects/view/ViewProject";
import ViewWorkPackage from "./layouts/Projects/view/ViewWorkPackage";
import DayOffCreate from "./layouts/day_off/create";
import Employees from "./layouts/employee";
import ChangeLogAndFeedback from "./layouts/ChangeLogAndFeedback/index";
import Notifications from "./layouts/notifications";
import Forms from "./layouts/forms";
import TimerCreate from "./layouts/timer";
import Inventory from "./layouts/Inventory/InventoryTable/index";

// @mui icons
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AssessmentSharpIcon from "@mui/icons-material/AssessmentSharp";
// import ViewKanbanSharpIcon from '@mui/icons-material/ViewKanbanSharp';
import AccountTreeSharpIcon from "@mui/icons-material/AccountTreeSharp";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import ViewListIcon from "@mui/icons-material/ViewList";
import BadgeIcon from "@mui/icons-material/Badge";
import ScheduleIcon from "@mui/icons-material/Schedule";
// import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import TimerIcon from "@mui/icons-material/Timer";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import SourceIcon from "@mui/icons-material/Source";
import Icon from "@mui/material/Icon";
import Reports from "./layouts/Reports/index";
import Kanban from "./layouts/kanban";
import WorkSpace from "./layouts/workspace";
import { Notification } from "rsuite";
import PollIcon from "@mui/icons-material/Poll";
import Survey from "./layouts/Survey/index.js";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    // icon: <Icon fontSize="small">dashboard</Icon>,
    icon: <ViewListIcon fontSize="small" />,
    route: "/dashboard",
    component: <Dashboard />,
    is_staff_required: false,
  },
  {
    type: "collapse",
    name: "Employees",
    key: "Employees",
    // icon: <Icon fontSize="small">table_view</Icon>,
    icon: <BadgeIcon fontSize="small" />,
    route: "/Employees",
    component: <Tables />,
    is_staff_required: false,
  },
  {
    name: "UpdateEmployee",
    key: "update",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables/update-employee/:id",
    component: <UpdateEmployee />,
    is_staff_required: false,
  },
  /*
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  */
  {
    type: "collapse",
    name: "Workload Scheduler",
    key: "scheduler",
    // icon: <Icon fontSize="small">calendar_month</Icon>,
    icon: <ScheduleIcon fontSize="small" />,
    route: "/scheduler",
    component: <Scheduler />,
    is_staff_required: false,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile/:id?",
    component: <Profile />,
    is_staff_required: false,
  },
  {
    type: "collapse",
    name: "Projects",
    key: "projects",
    icon: <AccountTreeSharpIcon fontSize="small" />,
    route: "/projects",
    component: <Projects />,
    is_staff_required: true,
  },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    icon: <AddIcCallIcon fontSize="small" />,
    route: "/clients",
    component: <ClientInfo />,
    is_staff_required: true,
  },
  {
    name: "CreateProject",
    key: "createproject",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/projects/createproject",
    component: <ProjectCreate />,
    is_staff_required: false,
  },
  {
    name: "ViewProject",
    key: "viewproject",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/projects/viewproject/:id",
    component: <ViewProject />,
    is_staff_required: false,
  },
  {
    name: "UpdateProject",
    key: "updateproject",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/projects/updateproject/:id",
    component: <ProjectUpdate />,
    is_staff_required: false,
  },
  {
    name: "ViewWorkPackage",
    key: "viewworkpackage",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/projects/viewproject/viewworkpackage/:id",
    component: <ViewWorkPackage />,
    is_staff_required: false,
  },
  {
    name: "Employees",
    key: "employees",
    icon: <Icon fontSize="small">people_alt</Icon>,
    route: "/employees",
    component: <Employees />,
    is_staff_required: true,
  },
  {
    name: "Day Off",
    key: "day_off",
    icon: <HolidayVillageIcon fontSize="small" />,
    route: "/create-day-off",
    component: <DayOffCreate />,
    is_staff_required: false,
  },
  {
    // type: "collapse",
    name: "Timer",
    key: "timer",
    icon: <TimerIcon fontSize="small" />,
    route: "/timer",
    component: <TimerCreate />,
  },
  {
    type: "collapse",
    name: "Report",
    key: "report",
    // icon: <AssessmentSharpIcon fontSize="small" />,
    icon: <QueryStatsIcon fontSize="small" />,
    route: "/reports",
    component: <Reports />,
    is_staff_required: true,
  },
  {
    name: "Kanban",
    key: "kanban",
    icon: <AssessmentSharpIcon fontSize="small" />,
    route: "/dashboard/kanban/:workspaceId",
    component: <Kanban />,
    is_staff_required: false,
  },
  {
    type: "collapse",
    name: "Work Space",
    key: "workSpace",
    // icon: <SpaceDashboardIcon fontSize="small" />,
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/kanban",
    component: <WorkSpace />,
    is_staff_required: false,
  },

  {
    type: "collapse",
    name: "Forms",
    key: "forms",
    // icon: <AssessmentSharpIcon fontSize="small" />,
    icon: <SourceIcon fontSize="small" />,
    route: "/forms",
    component: <Forms />,
    is_staff_required: true,
  },
  {
    type: "collapse",
    name: "Inventory",
    key: "inventory",
    icon: <Inventory2RoundedIcon fontSize="small" />,
    route: "/Inventory",
    component: <Inventory/>,
    is_staff_required: false,
  },
  {
    type: "collapse",
    name: "Survey",
    key: "survey",
    icon: <PollIcon fontSize="small" />,
    route: "/survey",
    component: <Survey />,
    is_staff_required: false,
  },
  {
    type: "collapse",
    name: "Changelog & Feedback",
    key: "changelogandfeedback",
    icon: <PublishedWithChangesIcon fontSize="small" />,
    route: "/changelog_and_feedback",
    component: <ChangeLogAndFeedback />,
    is_staff_required: false,
  },
  {
    // type: 'collapse',
    name: "Noticiations",
    key: "notifications",
    icon: <PublishedWithChangesIcon fontSize="small" />,
    route: "/notifications",
    component: <Notifications />,
    is_staff_required: false,
  },
  {
    // type: 'collapse',
    name: "asd",
    key: "asd",
    icon: <PublishedWithChangesIcon fontSize="small" />,
    route: "/asd",
    component: <Employees />,
    is_staff_required: false,
  },
];

export default routes;
