import { Box, Typography, IconButton } from "@mui/material";
import MarkChatReadOutlinedIcon from "@mui/icons-material/MarkChatReadOutlined";
import MarkChatUnreadOutlinedIcon from "@mui/icons-material/MarkChatUnreadOutlined";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  getUnreadNotificationCount,
  updateNotificationReadStatus,
} from "../../../apis/apis/index";
import { useData } from "context";
import { useTheme } from "@mui/material/styles";
import baseColors from "assets/theme/base/colors";
import darkColors from "assets/theme-dark/base/colors";

export const NotificationComponent = ({
  notification,
  token,
  setErrorMessage,
  handleOpenDialog,
  getNotifications,
}) => {
  const { dispatchNotification } = useData();
  const navigate = useNavigate();

  const formattedTimestamp = format(parseISO(notification.timestamp), "PPP");

  const theme = useTheme();
  const { main: light } = baseColors;
  const { main: dark } = darkColors;

  const handleReadClick = async (event) => {
    event.stopPropagation();

    await updateNotificationReadStatus(
      !notification.is_read,
      notification.id,
      token,
      setErrorMessage,
      handleOpenDialog
    );
    getNotifications();
    getUnreadNotificationCount(token).then((res) => {
      dispatchNotification({ type: "SET_DATA", payload: { data: res.count } });
    });
  };

  const handleNotificationClick = () => {
    switch (notification.type) {
      case "ERP_VERSION":
        navigate("/changelog_and_feedback");
        break;
      case "NEW_TASK":
        if (notification.workspace_id) {
          navigate(`/dashboard/kanban/${notification.workspace_id}`);
        } else {
          console.error("Workspace ID is missing in the notification data.");
        }
        break;
      case "NEW_EMPLOYEE":
        navigate(`/profile/${notification.employee_id}`);
        break;
      case "NEW_SURVEY":
        navigate("/survey");
        break;
      case "BIRTHDAY":
        navigate("/birthday");
        break;
      default:
        navigate("/notifications");
        break;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: !notification.is_read
          ? notification.color
          : "lightgrey",
        padding: "1vh", // Adjusted padding with viewport height units
        borderRadius: "0.5rem",
        boxShadow: 1,
        border: `1px solid ${
          theme.palette.mode === "light" ? light.border : dark.border
        }`,
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        marginBottom: "2vh", // Add some vertical space between notifications
      }}
      onClick={handleNotificationClick}
    >
      <Box
        sx={{
          display: "flex", // Create a row layout for icon and message
          alignItems: "center", // Align items in the center
        }}
      >
        {/* Left Icon */}
        <IconButton onClick={handleReadClick} size="small">
          {notification.is_read ? (
            <MarkChatUnreadOutlinedIcon
              sx={{
                color: "#000",
              }}
            />
          ) : (
            <MarkChatReadOutlinedIcon
              sx={{
                color: "#000",
              }}
            />
          )}
        </IconButton>

        {/* Notification message */}
        <Typography
          variant="body2"
          sx={{
            flexGrow: 1, // Make this element grow to take available space
            color: theme.palette.mode === "dark" ? "#000" : "#000",
            marginLeft: "1rem", // Use rem for consistent spacing
          }}
        >
          {notification.message}
        </Typography>
      </Box>

      {/* Bottom Date */}
      <Box
        sx={{
          textAlign: "right", // Align the date to the right
          marginTop: "0.5rem", // Small space above the date
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.mode === "dark" ? "#000" : "#000",
          }}
        >
          <b>Date:</b> {formattedTimestamp}
        </Typography>
      </Box>
    </Box>
  );
};

export default NotificationComponent;