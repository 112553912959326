import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import ReportTable from "./ReportTable";
import Scheduler from "./Scheduler";
import dayjs from "dayjs";

export default function EmployeeReports() {
  const [isSchedulerOpen, setSchedulerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleToggleScheduler = () => {
    setLoading(true);
    setSchedulerOpen(!isSchedulerOpen);

    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const handleSearch = (start, end) => {
    setLoading(true);
    setStartDate(start);
    setEndDate(end);

    setTimeout(() => {
      setLoading(false);
    }, 600);
  };

  useEffect(() => {
    const firstDayOfMonth = dayjs().startOf("month").format("YYYY-MM-DD");
    const today = dayjs().format("YYYY-MM-DD");

    handleSearch(firstDayOfMonth, today);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Box
        display="flex"
        flexGrow={1}
        sx={{
          gap: "1rem",
          overflow: "hidden",
        }}
      >
        {/* Scheduler Section */}
        <Scheduler
          isOpen={isSchedulerOpen}
          toggleDrawer={handleToggleScheduler}
          onSearch={handleSearch}
        />

        {/* ReportTable */}
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <ReportTable startDate={startDate} endDate={endDate} />
        )}
      </Box>
    </Box>
  );
}